export default {
    inject: ['toast'],
    data: {
        validationErrors: {},
    },
    methods: {
        clearAEH() {
            this.validationErrors = {};
        },
        handleApiError(apiResponse) {
            this.clearAEH();

            if (apiResponse.forbidden) {
                return this.$router.push('/permissions-error');
            }

            if (apiResponse.validation) {
                return this.validationErrors = apiResponse.validation;
            }

            if (apiResponse.exception) {
                return this.toast('success', apiResponse.exception);
            }
        },
        getFieldValidationError(field) {
            return this.validationErrors[field] || null;
        },
        deleteFieldValidationError(field) {
            this.validationErrors[field] = null;
        }
    }
}
