export default {
    methods: {
        async checkPushCommitmentStep(scopeStep) {
            if (scopeStep === 5) {
                return await this.redirectToProjectTasks()
            }

            return true;
        },
        async redirectToProjectTasks() {
            await this.$router.push({
                name: 'ProjectTasks',
                id: this.$route.params.id,
            });
        }
    }
}
