<template>
  <div class="sticky-footer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StickyFooter"
}
</script>

<style scoped lang="scss">
.sticky-footer {
  padding: 24px 0;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
  z-index: 9;
  border-top: 1px solid #C3CDD5;
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
