<template>
  <div class="instance-info">
    <h1 class="instance-info__name" :class="{'instance-info__name_disabled': nameDisabled}">
      {{ name }}
    </h1>
    <p class="instance-info__date">
      {{ date }}
    </p>

    <div v-if="infoList" class="instance-info__list">
      <span v-for="item in infoList" :key="item.label">
        {{ item.label }}: <strong>{{ item.value }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstanceInfo",
  props: ['name', 'date', 'nameDisabled', 'infoList']
}
</script>

<style scoped lang="scss">
.instance-info {
  &__name {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 8px;
    color: #1C262F;
    border-bottom: 1px solid #C3CDD5;

    &_disabled {
      color: #BAC6CF;
    }
  }

  &__date {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 12px;
    color: #677A89;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 48px;
    background: #F2F5F8;
    color: #677A89;
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 14px;
    margin-top: 16px;
  }
}
</style>
