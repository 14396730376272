export default {
    methods: {
        async triggerUnsavedChangesCheck(condition) {
            if (condition) {
                return await this.openUnsavedChangesModal();
            }

            return true;
        },
        async openUnsavedChangesModal() {
            return await this.$refs.confirmDialog
                .confirm({
                    text: 'You have unsaved changes. Are you sure you want to leave the page?',
                    confirmText: 'Yes, leave',
                    cancelText: 'Cancel',
                    reverse: true,
                })
                .then(response => {
                    if (response)
                        return true;

                    return Promise.reject(false);
                });
        }
    }
}
