<template>
    <CModal
        backdrop="static"
        alignment="center"
        class="addFromLibrary"
        :visible="modelValue"
        @show="fetchData"
        @close="handleClose">
        <CModalHeader>
            <CModalTitle>
                <h2>{{ title }}</h2>
                <CInputGroup>
                    <CButton v-if="navigation.length" variant="ghost" @click="handleBack">
                        <CIcon name="cilChevronLeft" />
                    </CButton>
                    <CFormInput v-model="search" placeholder="Search" @input="fetchData" />
                    <CInputGroupText>
                        <CIcon name="cilSearch" />
                    </CInputGroupText>
                </CInputGroup>
            </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <div :key="key" class="shortcut__list">
                <div
                    v-for="collection in collections"
                    :key="collection.id"
                    :class="`shortcut__list-item${collection.id === selectCollection ? ' active' : ''}`">
                    <div
                        class="d-flex align-items-center shortcut__list-item-wrap"
                        @click="selectCollection = collection.id">
                        <CIcon :name="icons[collection.item_type]" />
                        <div
                            class="shortcut__list-item-name"
                            @click="collection.item_type === 'template' ? handleChoose(collection) : ''">
                            {{ collection.name }}
                        </div>
                    </div>
                    <CIcon
                        :name="collection.item_type === 'template' ? 'cilPlus' : 'cilChevronRight'"
                        @click="handleChoose(collection)" />
                </div>
            </div>
        </CModalBody>
        <CModalFooter class="d-flex align-items-center justify-content-between"></CModalFooter>
    </CModal>
</template>

<script>
export default {
    name: 'FromLibrary',
    inject: ['toast'],
    props: {
        modelValue: { type: Boolean, default: false },
        exceptLibraryType: { type: Array, default: [] },
        templateOnly: { type: Array, default: [] },
        title: {},
    },
    emits: ['close', 'addTemplate', 'update:modelValue'],
    data: () => ({
        key: 0,
        search: '',
        collections: [],
        navigation: [],
        selectCollection: null,
        selectFolder: null,
        loading: false,
        icons: {
            collection: 'cilLayers',
            folder: 'cilFolder',
            template: 'cilFile',
        },
    }),
    methods: {
        handleClose() {
            this.$emit('update:modelValue', false);
        },
        filterByTemplateType(arr) {
            if (this.templateOnly.length) {
                arr = arr.filter(
                    (item) => item.item_type !== 'template' || this.templateOnly.includes(item.template_type),
                );
            }
            return arr;
        },
        fetchData() {
            this.loading = true;
            this.$http.library
                .librarySearch({
                    search: this.search,
                    only_published: true,
                    collection_id: this.selectCollection || undefined,
                    folder_id: this.selectFolder || undefined,
                })
                .then((res) => {
                    this.collections = res.data.items.filter(
                        (item) => !this.exceptLibraryType.includes(item.item_type),
                    );

                    this.collections = this.filterByTemplateType(this.collections);
                    this.key++;
                })
                .finally(() => (this.loading = false));
        },
        handleChoose(item = {}) {
            switch (item.item_type) {
                case 'collection':
                    this.navigation.push({ key: 'selectCollection', value: item.item_id });
                    this.selectCollection = item.item_id;
                    this.selectFolder = undefined;
                    this.search = '';
                    this.fetchData();
                    break;
                case 'folder':
                    this.navigation.push({ key: 'selectFolder', value: item.item_id });
                    this.selectCollection = undefined; // TODO delete
                    this.selectFolder = item.item_id;
                    this.search = '';
                    this.fetchData();
                    break;
                case 'template':
                    this.$emit('addTemplate', {
                        template_id: item.template_id,
                        template_name: item.name,
                        updated_at: item.modified_date,
                        modified_by: item.modified_by?.full_name,
                        type: item.template_type,
                        is_manually: true,
                    });
                    break;
            }
        },
        handleBack() {
            if (this.navigation.length > 1) {
                let item = this.navigation[this.navigation.length - 2];
                this.selectCollection = item.key === 'selectCollection' ? item.value : undefined;
                this.selectFolder = item.key === 'selectFolder' ? item.value : undefined;
                this.navigation.splice(this.navigation.length - 1, 1);
            } else {
                this.selectCollection = undefined;
                this.selectFolder = undefined;
                this.navigation = [];
            }

            this.fetchData();
        },
    },
};
</script>

<style lang="scss" scoped>
.addFromLibrary .modal-title {
    width: 90%;
}

.addFromLibrary .input-group .form-control {
    border-right: none;
}

.addFromLibrary .input-group .input-group-text {
    background: none;
}

.addFromLibrary .modal-header {
    position: relative;
}

.addFromLibrary .modal-header::after {
    border-bottom: 1px solid #d8dbe0;

    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 95%;
    margin-left: 0.9rem;
}

.shortcut {
    &__list {
        max-height: 300px;
        overflow: auto;

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 20px;

            svg {
                min-width: 1rem;
                margin-right: 0.5rem;
                cursor: pointer;
            }

            &-wrap {
                width: calc(100% - 30px) !important;
            }

            &-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: none;
                margin-right: 0.5rem;
                cursor: pointer;
                width: calc(100% - 40px);
            }
        }
    }
}
</style>
